import React, { useState } from 'react';
import './Testi.css';  // Ensure the CSS is imported

const Testi = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const testimonials = [
        {
            name: "Akash Veesle",
            text: "“Great I has transformed my career. The training and support I received were top-notch and have allowed me to excel in my field.”",
        },
        {
            name: "Priya Kumar",
            text: "“The comprehensive courses at Great I are unparalleled. I highly recommend their programs for anyone looking to advance in the aviation industry.”",
        },
        {
            name: "Ravi Sharma",
            text: "“The instructors at Great I are industry experts who provide invaluable insights and hands-on experience. It's the best decision I've made for my career.”",
        },
        {
            name: "Meera Patil",
            text: "“Great I offers a perfect blend of theoretical knowledge and practical training. Their programs have significantly boosted my confidence and skills.”",
        },
        {
            name: "Rajesh Singh",
            text: "“Thanks to Great I, I was able to secure a job in a top aviation company. Their career support and guidance are exceptional.”",
        },
        {
            name: "Sonal Mehta",
            text: "“The market research course at Great I provided me with the necessary tools to excel in my role. I am grateful for the opportunities it has opened up for me.”",
        }
    ];

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <section className="bg-gray-50 py-12 sm:py-16 lg:py-20">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col items-center">
                    <div className="text-center">
                        <p className="text-lg font-medium text-blue-600">623 People Recommend us
                            </p>
                        <h2 className="mt-4 text-3xl font-bold text-blue-900 sm:text-4xl xl:text-5xl">Have a look at
                            what our people say</h2>
                    </div>

                    <div className="mt-8 text-center md:order-3 md:mt-16">
                        <button
                            className="mb-20 rounded-lg border-2 border-blue-700 bg-orange-500 px-6 py-2 font-medium text-white transition hover:translate-y-1">More About our Courses
                        </button>
                    </div>

                    <div className="relative mt-10 md:order-2 md:mt-24">
                        <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                            <div
                                className="mx-auto h-full w-full max-w-5xl rounded-3xl opacity-30 blur-lg filter"></div>
                        </div>

                        <div
                            className="relative mx-auto grid max-w-lg grid-cols-1 gap-6 md:max-w-none md:grid-cols-3 lg:gap-10">
                            {testimonials.map((testimonial, index) => (
                                <div key={index} className={`flex flex-col overflow-hidden rounded-xl border shadow-sm ${currentIndex === index ? '' : 'hidden md:flex'}`}>
                                    <div className="flex flex-1 flex-col justify-between bg-white p-6 lg:px-7 lg:py-8">
                                        <div className="flex-1">
                                            <div className="flex items-center">
                                                {Array(5).fill().map((_, i) => (
                                                    <svg key={i} className="h-5 w-5 text-orange-500"
                                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path
                                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
                                                    </svg>
                                                ))}
                                            </div>

                                            <blockquote className="mt-8 flex-1">
                                                <p className="font-[400] text-xl italic text-blue-900">{testimonial.text}</p>
                                            </blockquote>
                                        </div>

                                        <div className="mt-8 flex items-center">
                                            <div className="ml-4">
                                                <p className="text-base font-bold text-blue-900">{testimonial.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="mt-8 flex justify-center md:hidden">
                            <button onClick={handlePrev} className="rounded-full border-gray-300 p-2">
                                <svg className="h-6 w-6 text-blue-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                            <button onClick={handleNext} className="rounded-full border-gray-300 p-2">
                                <svg className="h-6 w-6 text-blue-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testi;
