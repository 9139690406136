import React, {useState} from 'react';
import './Call.css';  // Ensure the CSS is imported
import Modal from "./pages/Modal"; // Adjust the import path as needed
const Call = () => {

    const [showModal, setShowModal] = useState(false);

    const handleKnowMoreClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <section className="mx-auto py-16">
            <div className="mx-auto flex w-full flex-col items-center justify-center sm:max-w-screen-sm md:max-w-screen-md lg:flex-row">
                <div className="text-center">
                    <h2 className="bg-clip-text text-3xl font-extrabold text-gray-700 sm:text-5xl">Get in touch</h2>
                    <p className="bg-gradient-to-r from-green-500 to-blue-600 bg-clip-text text-4xl font-extrabold text-transparent sm:text-6xl">Let's take your career to new heights</p>
                    <button
                        onClick={handleKnowMoreClick}
                        className="shadow-pink-600/30 mt-10 inline-flex h-12 items-center rounded-full bg-orange-500 px-6 py-3 text-xl font-light text-white shadow-md transition hover:translate-y-1 hover:scale-105 hover:bg-green-700 hover:shadow-lg"
                    >
                        Contact Us
                    </button>
                </div>
            </div>
            <Modal show={showModal} onClose={handleCloseModal} />
        </section>
    );
}

export default Call;
