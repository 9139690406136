import React from 'react';

const Footer = () => {
    return (
        <>
            <div className="container mx-auto py-9 md:py-12 px-4 md:px-6 bg-white">
                <h2 className="text-center text-3xl font-semibold mb-6">Training Partners</h2>
                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 lg:space-x-8">
                    <div className="flex flex-col md:flex-row items-center md:items-stretch justify-between bg-gray-50 dark:bg-gray-800 py-6 px-6 md:py-12 lg:px-12 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12">
                        <div className="flex flex-col items-center md:items-start justify-center md:w-1/2">
                            <h1 className="text-3xl lg:text-4xl font-semibold text-gray-800 dark:text-white">NSDC</h1>
                            <p className="text-base lg:text-xl text-gray-800 dark:text-white mt-2">Govt Of <span className="font-bold">India</span></p>
                        </div>
                        <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
                            <img src="https://nsdcindia.org/sites/default/files/logo.jpg" alt="Best Deal" className="w-full h-auto object-contain" />
                        </div>
                    </div>
                    <div className="md:w-4/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 bg-white dark:bg-white py-6 px-6 md:py-0 md:px-4 lg:px-6 flex flex-col justify-center relative">
                        <div className="flex flex-col justify-center">
                            <h1 className="text-3xl lg:text-4xl font-semibold text-gray-800 dark:text-white"></h1>
                            <p className="text-base lg:text-xl text-gray-800 dark:text-white"><span className="font-bold"></span></p>
                        </div>
                        <div className="flex justify-end md:absolute md:bottom-4 md:right-4 lg:bottom-0 lg:right-0">
                            <img src="https://glocaluniversity.edu.in/wp-content/uploads/2024/02/Logo-1-2048x690.png" alt="Game Console" className="w-full h-auto object-contain md:w-20 md:h-20 lg:w-full lg:h-full" />
                        </div>
                    </div>
                </div>
            </div>

            <footer className="relative mt-20 bg-gray-900 px-4 pt-20 pb-10">
                <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 rounded-xl border-4 border-orange-500 bg-white p-2">
                    <img className="h-16 w-16 object-contain" src="/foot.png" alt="Logo" />
                </div>
                <nav aria-label="Footer Navigation" className="mx-auto flex max-w-lg flex-wrap justify-center gap-10 text-center text-white">
                    <a href="/" className="font-medium">Home</a>
                    <a href="/about" className="font-medium">Our Courses</a>
                    <a href="https://wa.me/919447737973" className="font-medium">Whatsapp Now</a>
                    <a href="tel:+919447737973" className="font-medium">Call Now</a>
                    <a href="mailto:pramodresearch24@gmail.com" className="font-medium">Email us</a>
                </nav>
                <div className="text-center text-gray-300 mt-10">
                    <p className="mb-2">Great I Pvt Ltd</p>
                    <p className="mb-2">NO. 46/2271, CORAL SQUARE, SOUTH BAZAR, CIVIL STATION,</p>
                    <p className="mb-2">KANNUR, KERALA-670002</p>
                    <p className="mb-2">Phone: +91-9447-737-973</p>
                    <p className="mb-2">Email: pramodresearch24@gmail.com</p>
                    <p className="mb-2">Website: www.Great-I.com</p>
                </div>
                <p className="py-10 text-center text-gray-300">© 2024 Great-I PVT. Ltd. | All Rights Reserved | Developed by DBes Techno</p>
            </footer>

        </>
    );
}

export default Footer;
