import React, { useState } from 'react';
import Slider from '../Slider'; // Import the Slider component
import Testi from '../Testi';
import Call from '../Call';

const Home = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleToggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const handleKnowMoreClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="bg-white text-white">
            <Slider /> {/* Slider */}
            {/* VISION mission - starts */}
            <section className="bg-white dark:bg-gray-900 py-12">
                <div className="max-w-screen-xl mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                        {/* Vision Section */}
                        <div className="text-center md:border-r md:border-gray-300 dark:border-gray-700 p-4">
                            <img className="mx-auto w-24 h-24 mb-4" src="/images/vision.png" alt="Vision Icon" />
                            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">Vision</h2>
                            <p className="text-lg text-gray-700 dark:text-gray-300">
                                “Amalgamate the World through Training and Data insights. We empower the future of Aviation, Tourism, and Logistics”
                            </p>
                        </div>

                        {/* Mission Section */}
                        <div className="text-center p-4">
                            <img className="mx-auto w-24 h-24 mb-4" src="/images/mission.png" alt="Mission Icon" />
                            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">Mission</h2>
                            <p className="text-lg text-gray-700 dark:text-gray-300">
                                “Connecting the World through exceptional training for the next generation of Aviation, Tourism, and logistics professionals and fuelling innovation in world-class data-driven insights”
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* VISION mission - ends */}

            {/* Disciplines - starts */}
            <section className="py-20">
                <h1 className="mb-12 text-center font-sans text-5xl font-bold text-gray-900">Our Main Disciplines<span className="text-blue-600">.</span></h1>
                <div className="mx-auto grid max-w-screen-xl grid-cols-1 gap-6 p-6 md:grid-cols-2 lg:grid-cols-3">
                    <article className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl">
                        <a href="#">
                            <div className="relative flex items-end overflow-hidden rounded-xl">
                                <img src="/images/avaition.png" alt="Aviation" />
                            </div>
                            <div className="mt-1 p-2">
                                <h2 className="text-slate-700">Aviation</h2>
                                <p className="text-slate-400 mt-1 text-sm">Our aviation courses provide comprehensive training in ground staff operations, cabin crew responsibilities, and cargo handling. Students benefit from industry-expert instructors and practical sessions, ensuring readiness for diverse aviation careers. Graduates enjoy high employability in airlines, airports, and logistics companies.</p>
                                <div className="mt-3 flex items-end justify-between">
                                    <p>
                                        <span className="text-lg font-bold text-blue-500">Know More</span>
                                        <span className="text-slate-400 text-sm"></span>
                                    </p>
                                </div>
                            </div>
                        </a>
                    </article>
                    <article className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl">
                        <a href="#">
                            <div className="relative flex items-end overflow-hidden rounded-xl">
                                <img src="/images/tourism.png" alt="Tourism" />
                            </div>
                            <div className="mt-1 p-2">
                                <h2 className="text-slate-700">Tourism</h2>
                                <p className="text-slate-400 mt-1 text-sm">Our tourism courses cover essential skills for managing travel agencies, tour operations, and customer service in hospitality. With hands-on training and expert guidance, students gain practical knowledge for successful careers in the tourism industry, including roles in travel consultancy, tour guiding, and event planning.</p>
                                <div className="mt-3 flex items-end justify-between">
                                    <p>
                                        <span className="text-lg font-bold text-blue-500">Know More</span>
                                        <span className="text-slate-400 text-sm"></span>
                                    </p>
                                </div>
                            </div>
                        </a>
                    </article>
                    <article className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl">
                        <a href="#">
                            <div className="relative flex items-end overflow-hidden rounded-xl">
                                <img src="/images/logistics.png" alt="Logistics" />
                            </div>
                            <div className="mt-1 p-2">
                                <h2 className="text-slate-700">Logistics</h2>
                                <p className="text-slate-400 mt-1 text-sm">Our logistics courses provide in-depth knowledge of supply chain management, transportation, and warehousing. Students learn from industry experts and gain practical experience, preparing them for careers in logistics management, operations, and supply chain coordination, with high demand in various sectors.</p>
                                <div className="mt-3 flex items-end justify-between">
                                    <p>
                                        <span className="text-lg font-bold text-blue-500">Know More</span>
                                        <span className="text-slate-400 text-sm"></span>
                                    </p>
                                </div>
                            </div>
                        </a>
                    </article>
                </div>
            </section>
            {/* Disciplines - ENDS */}

            {/* Pramod - starts */}
            <section className="bg-white dark:bg-gray-900 py-12">
                <div className="max-w-screen-xl mx-auto px-4">
                    <div className="text-center mb-8">
                        <img className="mx-auto rounded-full w-32 h-32 object-cover" src="/images/pramod.png" alt="Pramod Kumar T" />
                    </div>
                    <h1 className="text-4xl font-bold text-gray-900 dark:text-white text-center mb-6">PRAMOD KUMAR T</h1>
                    <p className="text-lg text-gray-700 dark:text-gray-300 text-center max-w-3xl mx-auto mb-6">
                        Pramod Kumar T is the visionary founder of GRANDES ROUTE EDNBIZ AVIATION AND TOURISM INNOVATION (OPC) PRIVATE LIMITED, a pioneering startup dedicated to transforming education in the aviation and logistics sectors. With a strong academic background and extensive professional experience, Mr. Kumar has established himself as a prominent expert and educator in these dynamic fields.
                    </p>
                    <div className={`text-lg text-gray-700 dark:text-gray-300 space-y-4 max-w-4xl mx-auto ${isExpanded ? 'block' : 'hidden'}`}>
                        <p>Currently pursuing a part-time Ph.D. in Airport Management, Mr. Kumar holds an MBA in Airline & Airport Management, a Master's in Business and Administration from IGNOU, a Post Graduate Diploma in Marketing Management, and specialized diplomas in Travel and Tourism Senior Management, Airline Marketing, and Travel and Tourism Foundation from A.T.D.I, IATA, Montreal. Additionally, he obtained a Bachelor of Science degree from the University of Calicut.</p>
                        <p>In his illustrious career spanning over 25 years at the Kerala State Electricity Board (KSEB), Mr. Kumar serves as a Senior Superintendent in the Transmission Circle and fulfills the role of Nodal Officer, demonstrating leadership and expertise in managing complex projects and operations. His dedication and exceptional performance have earned him several accolades, including the State Level Outstanding Trainer Award, the Outstanding Zone President Award from JCI, and the Best Green Protocol Nodal Officer Award.</p>
                        <p>Since 2008, Mr. Kumar has been a dedicated trainer at RPTI (K.S.E.B), successfully training over 5000 staff members. He also serves as a Zone Trainer for JCI (U.S.A.) and a trainer for the Trauma Care Society, underscoring his commitment to education and training to nurture talent and foster growth in others. Beyond his professional achievements, Mr. Kumar is multilingual and proficient in English, Hindi, Russian, French, and Sanskrit.</p>
                        <p>Additionally, Mr. Kumar is an accomplished book writer and poet, further showcasing his diverse talents and dedication to lifelong learning and personal development. His broad range of skills and accolades highlight his significant contributions to his field and his unwavering commitment to excellence.</p>
                        <p>Under Mr. Kumar's direction, GRANDES ROUTE EDNBIZ AVIATION AND TOURISM INNOVATION aims to provide industry-specific education in aviation and logistics. His vision is to create a learning environment that integrates theoretical knowledge with practical experience, preparing students for prosperous careers in these rapidly evolving industries.</p>
                    </div>
                    <div className="text-center mt-6">
                        <button onClick={handleToggleReadMore} className="bg-blue-500 text-white px-4 py-2 rounded-lg">
                            {isExpanded ? 'Read Less' : 'Read More'}
                        </button>
                    </div>
                </div>
            </section>
            {/* Pramod - ends */}

            {/* Things you'll learn - Starts */}
            <div className="bg-green-600 mx-auto py-10 grid max-w-screen-xl grid-cols-1 text-white pl-6 pr-4 sm:px-20 lg:grid-cols-3">
                <div className="col-span-1 flex flex-col justify-center text-center sm:text-left md:pr-10">
                    <h1 className="mb-6 text-4xl">Things you'll learn</h1>
                    <p className="text-gray-400"></p>
                </div>
                <div className="col-span-2 mt-10 grid grid-cols-1 gap-6 rounded-2xl bg-gray-600 p-5 sm:p-10 md:grid-cols-2 lg:mt-0">
                    <div className="relative flex gap-5">
                        <div className="absolute -left-12 sm:left-auto flex h-10 w-10 items-center justify-center rounded-full bg-gray-800 text-lg text-gray-500 sm:relative md:bg-transparent md:text-5xl">01</div>
                        <div className="">
                            <h3 className="text-xl font-semibold">Aviation Courses</h3>
                            <p className="text-gray-400 mt-3">- Aviation Management</p>
                            <p className="text-gray-400 mt-3">- Airline Operations</p>
                            <p className="text-gray-400 mt-3">- Aviation Safety and Security</p>
                            <p className="text-gray-400 mt-3">- Airport Ground Handling</p>
                            <p className="text-gray-400 mt-3">- Passenger Service Agent Training</p>
                            <p className="text-gray-400 mt-3">- Ramp Handling</p>
                            <a href="/about" className="text-blue-500 mt-3 inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                </svg>
                                More
                            </a>
                        </div>
                    </div>
                    <div className="relative flex gap-5">
                        <div className="absolute -left-12 sm:left-auto flex h-10 w-10 items-center justify-center rounded-full bg-gray-800 text-lg text-gray-500 sm:relative md:bg-transparent md:text-5xl">02</div>
                        <div className="">
                            <h3 className="text-xl font-semibold">Tourism Courses</h3>
                            <p className="text-gray-400 mt-3">- Hospitality and Tourism Management</p>
                            <p className="text-gray-400 mt-3">- Travel and Tourism Operations</p>
                            <p className="text-gray-400 mt-3">- Event Management in Tourism</p>
                            <p className="text-gray-400 mt-3">- Culinary Tourism</p>
                            <p className="text-gray-400 mt-3">- Adventure Tourism</p>
                            <p className="text-gray-400 mt-3">- Cruise Line Management</p>
                            <a href="/about" className="text-blue-500 mt-3 inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                </svg>
                                More
                            </a>
                        </div>
                    </div>
                    <div className="relative flex gap-5">
                        <div className="absolute -left-12 sm:left-auto flex h-10 w-10 items-center justify-center rounded-full bg-gray-800 text-lg text-gray-500 sm:relative md:bg-transparent md:text-5xl">03</div>
                        <div className="">
                            <h3 className="text-xl font-semibold">Logistics Courses</h3>
                            <p className="text-gray-400 mt-3">- Supply Chain Management</p>
                            <p className="text-gray-400 mt-3">- Logistics Management</p>
                            <p className="text-gray-400 mt-3">- Logistics Market Research</p>
                            <p className="text-gray-400 mt-3">- International Trade and Logistics</p>
                            <p className="text-gray-400 mt-3">- Cargo Management</p>
                            <p className="text-gray-400 mt-3">- Freight Forwarding</p>
                            <a href="/about" className="text-blue-500 mt-3 inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                </svg>
                                More
                            </a>
                        </div>
                    </div>
                    <div className="relative flex gap-5">
                        <div className="absolute -left-12 sm:left-auto flex h-10 w-10 items-center justify-center rounded-full bg-gray-800 text-lg text-gray-500 sm:relative md:bg-transparent md:text-5xl">04</div>
                        <div className="">
                            <h3 className="text-xl font-semibold">Other Courses</h3>
                            <p className="text-gray-400 mt-3">- Services Industry Market Research</p>
                            <p className="text-gray-400 mt-3">- Tuition for IGNOU Management Course</p>
                            <a href="/about" className="text-blue-500 mt-3 inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                </svg>
                                More
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Things you'll learn - ends */}
            {/* Explore Courses Button */}
            <a href="/about">
                <button className="flex mx-auto mt-16 text-white bg-green-700 border-0 py-2 px-8 focus:outline-none hover:bg-orange-500 rounded text-lg">
                    Explore All Courses
                </button>
            </a>
            {/* Explore Courses Button */}

            {/* Why us - starts */}
            <div className="w-screen">
                <div className="mx-auto mt-10 w-full max-w-screen-xl px-4">
                    <div className="w-12 border-b-2 border-pink-500 lg:border-b-4"></div>
                    <h2 className="mt-6 mb-10 text-3xl font-semibold tracking-wide text-gray-800 sm:text-6xl">Why Great I?</h2>
                    <div className="flex flex-col justify-between lg:flex-row lg:flex-wrap">
                        <div className="mt-8 lg:mt-10 lg:w-1/2">
                            <div className="flex flex-col items-start lg:pr-16">
                                <div className="w-16">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
                                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="284.2684" y1="452.9162" x2="284.2684" y2="161.3189" className="gradient-element">
                                            <stop offset="0" className="primary-color-gradient" style={{ stopColor: '#d500f9' }}></stop>
                                            <stop offset="1" className="secondary-color-gradient" style={{ stopColor: '#ffea00' }}></stop>
                                        </linearGradient>
                                        <path fill="url(#SVGID_1_)" d="M404,215.8v94.4c0,8.5-1.2,16.9-3.7,25l-23.9,118.4H197.5c0,0-10.7-11.5-25.9-28.3c-15.5-17.2-3.4-44.7,19.7-44.7h60.3c38.3,0,69.3-31.2,69.3-69.7V182c0-7.5,6.8-13.1,14.1-11.6l32.6,6.6C396.4,184,404,205.4,404,215.8z"></path>
                                        <path d="M197.5,460c-2.1,0-4.3-0.9-5.8-2.5c-0.3-0.4-33.2-35.6-65.7-72.6c-67.5-77-67.5-86.4-67.5-91.4c0-19.2,9-30.4,16.6-36.5c8.4-6.7,19.5-10.5,30.3-10.5c2.6,0,5.2,0.5,7.7,1.4c14.6,5.8,23.8,13.5,36.4,24.2c3.9,3.3,8.1,6.8,12.9,10.7V86c0-10.9,3.8-22,10.5-30.4c6-7.6,17.2-16.8,36.5-16.8c19.2,0,30.4,9,36.5,16.6c6.7,8.4,10.5,19.5,10.5,30.3v60.7l97.3,19.5c0.1,0,0.2,0,0.3,0.1l15.3,3.1c0.1,0,0.2,0,0.3,0.1c33.7,8.2,42.6,34,42.6,46.5v93.8c0,9.1-1.3,18.1-3.9,26.8l-23.8,117.4c-0.8,3.7-4,6.4-7.8,6.4H235.2c-4.4,0-8-3.6-8-8s3.6-8,8-8h134.7l22.6-111.3c0-0.2,0.1-0.5,0.2-0.7c2.2-7.3,3.3-14.9,3.3-22.6v-93.8c0-8.1-6.3-25-30.2-30.9l-5.7-1.1v45c0,4.4-3.6,8-8,8s-8-3.6-8-8v-48.2l-35.9-7.2v65.4c0,4.4-3.6,8-8,8s-8-3.6-8-8v-68.6l-35.9-7.2v85.8c0,4.4-3.6,8-8,8s-8-3.6-8-8V85.7c0-10.7-6.5-30.9-30.9-30.9S178.3,75.2,178.3,86v213.1c0,3-1.7,5.8-4.4,7.2c-2.7,1.4-5.9,1.1-8.4-0.7c-11.1-8.3-19.6-15.5-26.4-21.2c-11.9-10-19.7-16.7-31.9-21.5c-0.6-0.2-1.2-0.3-1.9-0.3c-10.7,0-30.8,6.4-30.9,30.6c0.8,3.2,8,18,65.3,83.2c31.7,36.1,63.3,69.9,63.6,70.3c3,3.2,2.8,8.3-0.4,11.3C201.4,459.3,199.4,460,197.5,460z"></path>
                                        <g>
                                            <path fill="#d500f9" className="primary-color" d="M475.6,460.2H461v-53.8h14.6V460.2z"></path>
                                            <path fill="#ffea00" className="secondary-color" d="M475.6,229.8H461V176h14.6V229.8z M475.6,252.8H461v53.8h14.6V252.8z M475.6,329.6H461v53.8h14.6V329.6z M36,35v60.9L96.9,35H36z"></path>
                                        </g>
                                        <path d="M131.7,473c0,4.4-3.6,8-8,8H44.1c-4.4,0-8-3.6-8-8s3.6-8,8-8h79.5C128.1,465,131.7,468.6,131.7,473z M432.7,31.1c-4.4,0-8,3.6-8,8c0,11.4-9.3,20.8-20.8,20.8c-4.4,0-8,3.6-8,8s3.6,8,8,8c20.3,0,36.8-16.5,36.8-36.8C440.7,34.7,437.1,31.1,432.7,31.1z M482.7,75.8c4.4,0,8-3.6,8-8s-3.6-8-8-8c-11.4,0-20.8-9.3-20.8-20.8c0-4.4-3.6-8-8-8s-8,3.6-8,8C446,59.3,462.4,75.8,482.7,75.8z M482.7,81.1c-20.3,0-36.8,16.5-36.8,36.8c0,4.4,3.6,8,8,8s8-3.6,8-8c0-11.4,9.3-20.8,20.8-20.8c4.4,0,8-3.6,8-8S487.1,81.1,482.7,81.1z M404,81.1c-4.4,0-8,3.6-8,8s3.6,8,8,8c11.4,0,20.8,9.3,20.8,20.8c0,4.4,3.6,8,8,8s8-3.6,8-8C440.7,97.6,424.2,81.1,404,81.1z M89.7,179.7c4.4,0,8-3.6,8-8s-3.6-8-8-8c-5.7,0-10.4-4.7-10.4-10.4c0-5.7,4.7-10.4,10.4-10.4c4.4,0,8-3.6,8-8s-3.6-8-8-8c-14.6,0-26.4,11.8-26.4,26.4S75.1,179.7,89.7,179.7z M44.3,408.4h3.9v3.9c0,4.4,3.6,8,8,8s8-3.6,8-8v-3.9h3.9c4.4,0,8-3.6,8-8s-3.6-8-8-8h-3.9v-3.9c0-4.4-3.6-8-8-8s-8,3.6-8,8v3.9h-3.9c-4.4,0-8,3.6-8,8S39.9,408.4,44.3,408.4z"></path>
                                    </svg>
                                </div>
                                <h2 className="lg: mt-6 flex items-center text-base font-semibold tracking-wide text-gray-800 lg:mt-6 lg:text-2xl">
                                    <span className="mr-5 text-2xl">Learn Interactively</span>
                                </h2>
                                <p className="lg: mt-1 text-sm tracking-normal text-gray-800 lg:mt-1 lg:text-xl">Our courses are designed to be interactive and engaging, providing hands-on experience and real-world insights.</p>
                            </div>
                        </div>
                        <div className="mt-8 lg:mt-10 lg:w-1/2">
                            <div className="flex flex-col items-start lg:pr-16">
                                <div className="w-16">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
                                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="223.5286" y1="383.3321" x2="223.5286" y2="130.4198" className="gradient-element">
                                            <stop offset="0" className="primary-color-gradient" style={{ stopColor: '#d500f9' }}></stop>
                                            <stop offset="1" className="secondary-color-gradient" style={{ stopColor: '#ffea00' }}></stop>
                                        </linearGradient>
                                        <path fill="url(#SVGID_1_)" d="M370.9,244c0,81.4-66,147.5-147.5,147.5c-78.6,0-142.9-61.5-147.3-139.1c7.1-2.4,17.5-1.9,20.9,6.4c19.7,47.5,54.5,82.5,103.5,90.2c41.7,6.6,69.3-9.4,82.8-24.8c38.9-44.6,42.5-137.4-58.3-190.1c-15.8-8.2-6.4-33.6,11.3-33.6h0.6c10.9,0,21.5,1.1,31.8,3.1C328.1,122.8,370.9,178.4,370.9,244z"></path>
                                        <path d="M73.8,117.3c-1.4,1.1-3.1,1.6-4.8,1.6c-2.4,0-4.8-1.1-6.4-3.2c-2.7-3.5-1.9-8.5,1.6-11.2l90.1-67.6c3.5-2.6,8.5-1.9,11.2,1.6l13.5,18c2.7,3.5,1.9,8.5-1.6,11.2c-3.5,2.7-8.5,1.9-11.2-1.6l-8.7-11.6L73.8,117.3z M273.5,374.1c-15.9,6.2-32.8,9.4-50.1,9.4c-26.9,0-48-5.6-68.5-18.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.8-0.5-1.6-0.8-2.5-0.9c0,0,0,0,0,0c-1.2-0.2-2.4-0.2-3.5,0.2c-1.2,0.3-2.3,0.9-3.3,1.8c-0.6,0.6-1.2,1.3-1.6,2.1c-0.1,0.1-0.1,0.2-0.2,0.3l-26.2,51.1l-5.8-3l16.8-33.6c2-4,0.4-8.8-3.6-10.7s-8.8-0.4-10.7,3.6l-20.4,40.8c-2,3.9-0.4,8.7,3.5,10.7l20,10.1c1.2,0.6,2.4,0.9,3.6,0.9c2.9,0,5.7-1.6,7.1-4.3l26.1-50.8c20.8,11.2,42.6,16.2,69.4,16.2c19.4,0,38.2-3.5,56-10.5c4.1-1.6,6.1-6.2,4.5-10.4C282.3,374.5,277.7,372.5,273.5,374.1z M226.2,138.8c-4.4,0-8,3.6-8,8v106c0,2.1,0.9,4.2,2.4,5.7c1.5,1.5,3.5,2.3,5.6,2.3c0,0,0.1,0,0.1,0l81-1.2c4.4-0.1,7.9-3.7,7.9-8.1c-0.1-4.4-3.6-7.9-8-7.9c0,0-0.1,0-0.1,0l-72.9,1.1v-97.9C234.2,142.3,230.6,138.8,226.2,138.8z M382.6,104.5l-90.1-67.6c-1.7-1.3-3.8-1.8-5.9-1.5c-2.1,0.3-4,1.4-5.3,3.1l-13.5,18c-2.6,3.5-1.9,8.5,1.6,11.2c3.5,2.6,8.5,1.9,11.2-1.6l8.7-11.6l83.7,62.8c1.4,1.1,3.1,1.6,4.8,1.6c2.4,0,4.8-1.1,6.4-3.2C386.9,112.2,386.2,107.2,382.6,104.5z M377.8,245.1c0-85.1-69.3-154.4-154.4-154.4C138.3,90.7,69,159.9,69,245.1c0,44.6,19.4,87.1,53.1,116.5c3.3,2.9,8.4,2.5,11.3-0.8c2.9-3.3,2.6-8.4-0.8-11.3C102.4,323.1,85,285.1,85,245.1c0-76.3,62.1-138.4,138.4-138.4c76.3,0,138.4,62.1,138.4,138.4c0,49.2-26.5,95.1-69.1,119.8c-3.7,2.1-5,6.7-3.1,10.5l29.3,58.6c1.4,2.8,4.2,4.4,7.2,4.4c1.2,0,2.4-0.3,3.6-0.8c4-2,5.6-6.8,3.6-10.7l-26-52C350.9,346.4,377.8,297.4,377.8,245.1z"></path>
                                        <path d="M483.5,468.6c0,4.4-3.6,8-8,8H212.1c-4.4,0-8-3.6-8-8s3.6-8,8-8h263.3C479.9,460.6,483.5,464.2,483.5,468.6z M71.8,457.6H56v-15.8c0-4.4-3.6-8-8-8s-8,3.6-8,8v15.8H24.2c-4.4,0-8,3.6-8,8s3.6,8,8,8H40v15.8c0,4.4,3.6,8,8,8s8-3.6,8-8v-15.8h15.8c4.4,0,8-3.6,8-8S76.2,457.6,71.8,457.6z M501.7,59.7c-20.3,0-36.8,16.5-36.8,36.8c0,4.4,3.6,8,8,8s8-3.6,8-8c0-11.4,9.3-20.8,20.8-20.8c4.4,0,8-3.6,8-8S506.2,59.7,501.7,59.7z M423,75.7c11.4,0,20.8,9.3,20.8,20.8c0,4.4,3.6,8,8,8s8-3.6,8-8c0-20.3-16.5-36.8-36.8-36.8c-4.4,0-8,3.6-8,8S418.6,75.7,423,75.7z M423,54.5c20.3,0,36.8-16.5,36.8-36.8c0-4.4-3.6-8-8-8s-8,3.6-8,8c0,11.4-9.3,20.8-20.8,20.8c-4.4,0-8,3.6-8,8S418.6,54.5,423,54.5z M501.7,54.5c4.4,0,8-3.6,8-8s-3.6-8-8-8c-11.4,0-20.8-9.3-20.8-20.8c0-4.4-3.6-8-8-8s-8,3.6-8,8C465,38,481.5,54.5,501.7,54.5z M326,438.3h125.5c4.4,0,8-3.6,8-8s-3.6-8-8-8H326c-4.4,0-8,3.6-8,8S321.6,438.3,326,438.3z"></path>
                                        <g>
                                            <path fill="#ffea00" className="secondary-color" d="M78.5,20.5L17.5,81.4V20.5H78.5z"></path>
                                            <path fill="#d500f9" className="primary-color" d="M464.6,142.9h21.8v142.8h-21.8V142.9z"></path>
                                            <path fill="#ffea00" className="secondary-color" d="M428.7,142.9h21.8v142.8h-21.8V142.9z"></path>
                                        </g>
                                    </svg>
                                </div>
                                <h2 className="lg: mt-6 flex items-center text-base font-semibold tracking-wide text-gray-800 lg:mt-6 lg:text-2xl">
                                    <span className="mr-5 text-2xl">Learn Faster</span>
                                </h2>
                                <p className="lg: mt-1 text-sm tracking-normal text-gray-800 lg:mt-1 lg:text-xl">With our state-of-the-art training programs, you will be able to grasp concepts and skills at an accelerated pace.</p>
                            </div>
                        </div>
                        <div className="mt-8 lg:mt-10 lg:w-1/2">
                            <div className="flex flex-col items-start lg:pr-16">
                                <div className="w-16">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
                                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="197.4505" y1="414.8024" x2="187.5514" y2="248.4989" className="gradient-element">
                                            <stop offset="0" className="primary-color-gradient" style={{ stopColor: '#d500f9' }}></stop>
                                            <stop offset="1" className="secondary-color-gradient" style={{ stopColor: '#ffea00' }}></stop>
                                        </linearGradient>
                                        <path fill="url(#SVGID_1_)" d="M45.6,460L280,238.7c11.3-10.6,28.7-11,40.5-1l22.1,18.9l-186,162.7c0,0-67.4,2.7-101,50.6L45.6,460z"></path>
                                        <path d="M55.6,478c-2,0-4.1-0.8-5.7-2.3l-17.1-17.1c-1.5-1.5-2.3-3.5-2.3-5.7s0.8-4.2,2.3-5.7c33.8-33.8,42.6-53.3,42.6-94.8v-4.6c0-2,0.7-3.9,2-5.3l108.4-121.7c2.9-3.3,8-3.6,11.3-0.7c3.3,2.9,3.6,8,0.7,11.3L91.4,350.9v1.6c0,43.3-9.5,66.9-41.7,100.4l5.9,5.9c33.5-32.1,57.2-41.7,100.4-41.7h1.6L346.4,249c1.7-1.5,3.9-2,6.2-2c3.3,0.4,6.4,0.5,9.5,0.5c55.7,0,101-45.3,101-101c0-55.7-45.3-101-101-101s-101,45.3-101,101c0,4.6,0.4,9.4,1.2,15l0.2,1.2l62.5,57.9c8.7,0.7,34.7,2.7,37.2,2.8c13.5,0,26.6-3.5,38.2-10.2l-84.4-84.4c-1.5-1.5-2.3-3.5-2.3-5.7c0-2.1,0.8-4.2,2.3-5.7l17.1-17.1c3.1-3.1,8.2-3.1,11.3,0l91.3,91.3c3.1,3.1,3.1,8.2,0,11.3c-3.1,3.1-8.2,3.1-11.3,0l-85.7-85.7l-5.8,5.8l85.7,85.7c1.6,1.6,2.5,3.9,2.3,6.2c-0.2,2.3-1.3,4.4-3.2,5.8c-16.2,12.1-35.4,18.5-55.6,18.5c-3.2,0-37.6-2.7-41.3-3.1c-0.9-0.1-1.7-0.3-2.5-0.7c0,0,0,0,0,0l0,0c-0.8-0.4-1.5-0.9-2.2-1.5l-66.5-61.6c-1.3-1.2-2.2-2.9-2.5-4.7l-0.6-4c-1-6.4-1.4-11.9-1.4-17.3c0-64.5,52.5-117,117-117s117,52.5,117,117c0,64.5-52.5,117-117,117c-2.5,0-5-0.1-7.6-0.3L166,431.1c-1.5,1.3-3.4,2-5.3,2h-4.6c-41.5,0-61,8.8-94.8,42.6C59.7,477.3,57.7,478,55.6,478z"></path>
                                        <path fill="#ffea00" className="secondary-color" d="M490.4,379.2v44.5H446v-44.5H490.4z M446,490.6h44.5v-44.5H446V490.6z M377.6,490.6h44.5v-44.5h-44.5V490.6z"></path>
                                        <path d="M498.8,287.4c0,4.4-3.6,8-8,8h-3.9v3.9c0,4.4-3.6,8-8,8s-8-3.6-8-8v-3.9H467c-4.4,0-8-3.6-8-8s3.6-8,8-8h3.9v-3.9c0-4.4,3.6-8,8-8s8,3.6,8,8v3.9h3.9C495.2,279.4,498.8,282.9,498.8,287.4z M336.2,411.4h-43c-4.4,0-8,3.6-8,8s3.6,8,8,8h43c4.4,0,8-3.6,8-8S340.7,411.4,336.2,411.4z M415.1,374.2H299.8c-4.4,0-8,3.6-8,8s3.6,8,8,8h115.2c4.4,0,8-3.6,8-8S419.5,374.2,415.1,374.2z M459,57.3c0,4.4,3.6,8,8,8s8-3.6,8-8v-3.9h3.9c4.4,0,8-3.6,8-8s-3.6-8-8-8H475v-3.9c0-4.4-3.6-8-8-8s-8,3.6-8,8v3.9h-3.9c-4.4,0-8,3.6-8,8s3.6,8,8,8h3.9V57.3z M69,173.3c-4.2,4.2-4.2,11,0,15.2s11,4.2,15.2,0c4.2-4.2,4.2-11,0-15.2C80,169.1,73.2,169.1,69,173.3z M37.2,84.9c20.3,0,36.8-16.5,36.8-36.8c0-4.4-3.6-8-8-8s-8,3.6-8,8c0,11.4-9.3,20.8-20.8,20.8c-4.4,0-8,3.6-8,8S32.8,84.9,37.2,84.9z M115.9,68.9c-11.4,0-20.8-9.3-20.8-20.8c0-4.4-3.6-8-8-8s-8,3.6-8,8c0,20.3,16.5,36.8,36.8,36.8c4.4,0,8-3.6,8-8S120.4,68.9,115.9,68.9z M115.9,90.2c-20.3,0-36.8,16.5-36.8,36.8c0,4.4,3.6,8,8,8s8-3.6,8-8c0-11.4,9.3-20.8,20.8-20.8c4.4,0,8-3.6,8-8S120.4,90.2,115.9,90.2z M73.9,126.9c0-20.3-16.5-36.8-36.8-36.8c-4.4,0-8,3.6-8,8s3.6,8,8,8c11.4,0,20.8,9.3,20.8,20.8c0,4.4,3.6,8,8,8S73.9,131.4,73.9,126.9z M249.5,218.1h-92.9c-4.4,0-8,3.6-8,8s3.6,8,8,8h92.9c4.4,0,8-3.6,8-8S254,218.1,249.5,218.1z M212.3,97.2c4.4,0,8-3.6,8-8s-3.6-8-8-8c-5.7,0-10.4-4.7-10.4-10.4s4.7-10.4,10.4-10.4c4.4,0,8-3.6,8-8s-3.6-8-8-8c-14.6,0-26.4,11.8-26.4,26.4S197.7,97.2,212.3,97.2z M249,156.7l-24.9,22.5l-36.5,0.2c-4.4,0-8,3.6-7.9,8.1c0,4.4,3.6,7.9,8,7.9c0,0,0,0,0.1,0l39.6-0.3c2,0,3.9-0.8,5.3-2.1l27.1-24.6c3.3-3,3.5-8,0.6-11.3C257.3,154,252.2,153.7,249,156.7z"></path>
                                    </svg>
                                </div>
                                <h2 className="lg: mt-6 flex items-center text-base font-semibold tracking-wide text-gray-800 lg:mt-6 lg:text-2xl">
                                    <span className="mr-5 text-2xl">Personalize Your Learning</span>
                                </h2>
                                <p className="lg: mt-1 text-sm tracking-normal text-gray-800 lg:mt-1 lg:text-xl">Our personalized learning paths ensure that you gain the skills needed to excel in your chosen field.</p>
                            </div>
                        </div>
                        <div className="mt-8 lg:mt-10 lg:w-1/2">
                            <div className="flex flex-col items-start lg:pr-16">
                                <div className="w-16">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
                                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="486.0444" y1="259.5021" x2="42.3063" y2="259.5021" className="gradient-element">
                                            <stop offset="0" className="primary-color-gradient" style={{ stopColor: '#d500f9' }}></stop>
                                            <stop offset="1" className="secondary-color-gradient" style={{ stopColor: '#ffea00' }}></stop>
                                        </linearGradient>
                                        <path fill="url(#SVGID_1_)" d="M496.8,369.7H58.2c-11.6,0-21-9.4-21-21V170.7c0-11.8,9.6-21.4,21.4-21.4h438.1V369.7z"></path>
                                        <path d="M510.4,103.4v322.9c0,8.8-7.2,16-16,16H265.9v33.9h178.4c4.4,0,8,3.6,8,8s-3.6,8-8,8H67.8c-4.4,0-8-3.6-8-8s3.6-8,8-8h182.1v-38.8c0-0.5,0.1-1,0.1-1.5c-0.1-0.5-0.1-1-0.1-1.5c0-4.4,3.6-8,8-8h236.6v-48.6H385.9c-4.4,0-8-3.6-8-8s3.6-8,8-8h108.6V103.4c0-4.4,3.6-8,8-8S510.4,99,510.4,103.4z M48.8,391.6c5,0,9,4.1,9,9s-4.1,9-9,9s-9-4.1-9-9S43.8,391.6,48.8,391.6z M78.8,391.6c5,0,9,4.1,9,9s-4.1,9-9,9s-9-4.1-9-9S73.8,391.6,78.8,391.6z M108.8,391.6c5,0,9,4.1,9,9s-4.1,9-9,9s-9-4.1-9-9S103.8,391.6,108.8,391.6z M232,434.3c0-4.4-3.6-8-8-8H17.6V111.4h380.5c4.4,0,8-3.6,8-8s-3.6-8-8-8H17.6c-8.8,0-16,7.2-16,16v314.9c0,8.8,7.2,16,16,16H224C228.4,442.3,232,438.7,232,434.3z"></path>
                                        <path d="M312.4,24.6c0-5,4.1-9,9-9s9,4.1,9,9s-4.1,9-9,9S312.4,29.6,312.4,24.6z M281.5,33.7c5,0,9-4.1,9-9s-4.1-9-9-9s-9,4.1-9,9S276.5,33.7,281.5,33.7z M77.8,36H62V20.2c0-4.4-3.6-8-8-8s-8,3.6-8,8V36H30.2c-4.4,0-8,3.6-8,8s3.6,8,8,8H46v15.8c0,4.4,3.6,8,8,8s8-3.6,8-8V52h15.8c4.4,0,8-3.6,8-8S82.2,36,77.8,36z M361.5,33.7c5,0,9-4.1,9-9s-4.1-9-9-9s-9,4.1-9,9S356.5,33.7,361.5,33.7z M478.6,90.1c4.4,0,8-3.6,8-8s-3.6-8-8-8c-11.4,0-20.8-9.3-20.8-20.8c0-4.4-3.6-8-8-8s-8,3.6-8,8C441.9,73.7,458.4,90.1,478.6,90.1z M363.5,59.8h-215c-4.4,0-8,3.6-8,8s3.6,8,8,8h215c4.4,0,8-3.6,8-8S367.9,59.8,363.5,59.8z M478.6,95.4c-20.3,0-36.8,16.5-36.8,36.8c0,4.4,3.6,8,8,8s8-3.6,8-8c0-11.4,9.3-20.8,20.8-20.8c4.4,0,8-3.6,8-8S483,95.4,478.6,95.4z M428.6,45.4c-4.4,0-8,3.6-8,8c0,11.4-9.3,20.8-20.8,20.8c-4.4,0-8,3.6-8,8s3.6,8,8,8c20.3,0,36.8-16.5,36.8-36.8C436.6,49,433,45.4,428.6,45.4z M399.9,95.4c-4.4,0-8,3.6-8,8s3.6,8,8,8c11.4,0,20.8,9.3,20.8,20.8c0,4.4,3.6,8,8,8s8-3.6,8-8C436.6,111.9,420.1,95.4,399.9,95.4z"></path>
                                    </svg>
                                </div>
                                <h2 className="lg: mt-6 flex items-center text-base font-semibold tracking-wide text-gray-800 lg:mt-6 lg:text-2xl">
                                    <span className="mr-5 text-2xl">Build Real-World Projects</span>
                                </h2>
                                <p className="lg: mt-1 text-sm tracking-normal text-gray-800 lg:mt-1 lg:text-xl">Engage in real-world projects that will prepare you for the challenges and opportunities in aviation, tourism, and logistics.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why us - ends */}

            {/* Aviation - starts */}
            <section className="relative overflow-hidden bg-gray-100 py-12 sm:py-16 lg:py-20">
                <div className="absolute h-72 w-72 scale-125 -right-8 -bottom-10">
                    <div className="absolute h-60 w-60 rounded-2xl border-4 border-orange-500"></div>
                    <div className="absolute h-60 w-60 translate-x-3 translate-y-3 rounded-2xl border-4 border-green-600"></div>
                    <div className="absolute h-60 w-60 translate-x-6 translate-y-6 rounded-2xl border-4 border-blue-600"></div>
                </div>
                <div className="mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="sm:text-center">
                        <h2 className="text-3xl font-semibold leading-7 text-gray-900 sm:text-4xl xl:text-5xl">
                            Aviation Industry is <br className="sm:hidden"/>
                            growing rapidly
                        </h2>
                        <hr className="mt-4 h-1.5 w-32 border-none bg-blue-600 sm:mx-auto sm:mt-8"/>
                    </div>

                    <div className="mx-auto mt-20 grid max-w-screen-lg grid-cols-1 gap-x-8 gap-y-12 text-center sm:text-left md:grid-cols-3">
                        <div className="backdrop-blur-lg relative mb-3 rounded-3xl border bg-white/70 px-12 py-10 text-left shadow lg:px-12">
                            <p className="relative text-5xl font-black text-orange-500">50,000+</p>
                            <p className="relative mt-5 text-gray-600">Aviation Jobs Added Annually</p>
                        </div>

                        <div className="backdrop-blur-lg relative mb-3 rounded-3xl border bg-white/70 px-12 py-10 text-left shadow lg:px-12">
                            <p className="relative text-5xl font-black text-orange-500">75%</p>
                            <p className="relative mt-5 text-gray-600">Growth in Demand for Aviation Professionals</p>
                        </div>

                        <div className="backdrop-blur-lg relative mb-3 rounded-3xl border bg-white/70 px-12 py-10 text-left shadow lg:px-12">
                            <p className="relative m-0 text-5xl font-black text-orange-500">10,000+</p>
                            <p className="relative mt-5 text-gray-600">Career Opportunities in Aviation</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Aviation - ends */}

            <Testi />  {/* Use the Testi component here */}

            {/* Call to action - starts */}
            <Call />  {/* Call to action */}
            {/* Call to action - ends */}

            <style>
                {`
                    @keyframes slide {
                        0% { transform: translateY(0); }
                        50% { transform: translateY(-100%); }
                        100% { transform: translateY(0); }
                    }
                    .animate-slide {
                        animation: slide 10s infinite ease-in-out;
                    }
                `}
            </style>
        </div>
    );
}

export default Home;
